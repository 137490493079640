// constants imported from mobile folder

import {getVenueRoles} from "../modules/App/AppReducer";

export const DISPLAY_FORMAT = 'L';
export const ISO_FORMAT = 'YYYY-MM-DD';
export const ISO_MONTH_FORMAT = 'YYYY-MM';

export const imageKitUrl = 'https://ik.imagekit.io/venuemonk'

export const AWS_SOURCE_IMAGE_URL = `https://cdn.venuemonk.com`;
export const AWS_RAW_SOURCE_IMAGE_URL = 'https://venuemonk-images.s3.ap-south-1.amazonaws.com';
export const GUMLET_URL_BASE_URL = `https://vmnk.gumlet.io`
export const IMAGEKIT_BUCKET_NAME = 'venuemonk';
export const IMAGEKIT_SOURCE_IMAGE_URL = `https://ik.imagekit.io/${IMAGEKIT_BUCKET_NAME}`;


export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const VERTICAL_ORIENTATION = 'vertical';
export const VERTICAL_SCROLLABLE = 'verticalScrollable';

export const ICON_BEFORE_POSITION = 'before';
export const ICON_AFTER_POSITION = 'after';

export const INFO_POSITION_TOP = 'top';
export const INFO_POSITION_BOTTOM = 'bottom';
export const INFO_POSITION_BEFORE = 'before';
export const INFO_POSITION_AFTER = 'after';

export const ANCHOR_LEFT = 'left';
export const ANCHOR_RIGHT = 'right';

export const OPEN_DOWN = 'down';
export const OPEN_UP = 'up';

export const DAY_SIZE = 39;
export const BLOCKED_MODIFIER = 'blocked';
export const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];

export const FANG_WIDTH_PX = 20;
export const FANG_HEIGHT_PX = 10;
export const DEFAULT_VERTICAL_SPACING = 22;

export const MODIFIER_KEY_NAMES = new Set(['Shift', 'Control', 'Alt', 'Meta']);

export const VENDOR_MODEL = "vendors"

export const roleOptions = [
    {
        value: 'member',
        label: 'Member'
    },
    {
        value: 'admin',
        label: 'Admin'
    },
    {
        value: 'owner',
        label: 'Owner'
    }
]


export const callingStatusList = [
    {
        value: "Not Yet Call",
        label: "Not Yet Call"
    },
    {
        value: "Call Later",
        label: "Call Later"
    },
    {
        value: "Language Problem",
        label: "Language Problem"
    },
    {
        value: "Busy",
        label: "Busy"
    },
    {
        value: "Failed",
        label: "Failed"
    },
    {
        value: "Disconnected",
        label: "Disconnected"
    },
    {
        value: "Not Connected",
        label: "Not Connected"
    },
    {
        value: "Abandoned",
        label: "Abandoned"
    },
    {
        value: "Switch Off",
        label: "Switch Off"
    },
    {
        value: "Not Picking",
        label: "Not Picking"
    },
    {
        value: "Answered",
        label: "Answered"
    },
    {
        value: "Follow Up",
        label: "Follow Up"
    },
    {
        value: "Hang Up",
        label: "Hang Up"
    }
]


export const leadSource = [
    {
        value: 'WhatsApp',
        label: 'WhatsApp'
    },
    {
        value: 'Instagram',
        label: 'Instagram'
    },
    {
        value: 'Facebook',
        label: 'Facebook'
    },
    {
        value: 'Website',
        label: 'Website'
    },
    {
        value: 'Others',
        label: 'Others'
    },
]

export const OperationalCity = [
    {
        value: 'gurgaon',
        label: 'Gurgaon'
    },
    {
        value: 'delhi',
        label: 'Delhi'
    },
    {
        value: 'faridabad',
        label: 'Faridabad'
    },
    {
        value: 'ghaziabad',
        label: 'Ghaziabad'
    },
    {
        value: 'noida',
        label: 'Noida'
    },
    {
        value: 'jaipur',
        label: 'Jaipur'
    },
]

export const VendorList = [
    {
        value: 'photographer',
        label: 'Photographer'
    },
    {
        value: 'mehandi-artist',
        label: 'Mehendi-Artist'
    },
    {
        value: 'makeup-artist',
        label: 'Makeup-Artist'
    },
    {
        value: 'decorator',
        label: 'Decorator'
    },
    {
        value: 'event-planner',
        label: 'Event-Planner'
    },
    {
        value: 'wedding-stylist',
        label: 'Wedding-Stylis'
    },
    {
        value: 'caterer',
        label: 'Caterer'
    },
    {
        value: 'choreographer',
        label: 'Choreographe'
    },
    {
        value: 'dj',
        label: 'Dj'
    },
    {
        value: 'anchor',
        label: 'Anchor'
    },
    {
        value: 'wedding-entertainer',
        label: 'Wedding-Entertainer'
    },
    {
        value: 'pandit',
        label: 'Pandit'
    },
    {
        value: 'car',
        label: 'Car'
    },
    {
        value: 'bartender',
        label: 'Bartender'
    },
    {
        value: 'event-equipment',
        label: 'Event-Equipment'
    },

]